<template>
  <div class="car_list">
    <el-button type="primary" icon="el-icon-circle-plus" circle class="addCar"
               @click="showProductCategoryAddForm"></el-button>
    <!--车队添加-->
    <el-dialog
        title="添加车队"
        :fullscreen="true"
        :visible="carAddVisible"
        width="30%"
        :before-close="addHandleClose">

      <span>
          <el-form ref="addForm" :model="carForm" label-width="80px" :rules="rules">
            <el-form-item label="车队1" prop="carName1">
              <el-input type="text" v-model="carForm.carName1" placeholder="第一个车队名字" ></el-input>
            </el-form-item>

            <el-form-item label="车队2" prop="carName2">
              <el-input type="text" v-model="carForm.carName2" placeholder="第二个车队名字"></el-input>
            </el-form-item>

            <el-form-item label="车队容量" prop="joinChannelLimit">
              <el-input type="text" @input="carJoinChannelLimitNum()" v-model="carForm.joinChannelLimit" placeholder="输入数字"></el-input>
            </el-form-item>

            <el-form-item label="车队门槛" prop="carPower">
               <el-select v-model="carForm.carPower" placeholder="请选择上车门槛">
                <el-option
                    v-for="item in carPowers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="车队类型" prop="type">
               <el-select v-model="carForm.type" placeholder="请选择车队类型">
                <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
      </span>

      <span slot="footer" class="dialog-footer">
    <el-button @click="carAddVisible = false">取 消</el-button>
    <el-button type="primary" @click="addCar">添加</el-button>
  </span>
    </el-dialog>

    <!--  车队修改-->
    <el-dialog
        :fullscreen="true"
        title="车队信息修改"
        :visible="carEditVisible"
        width="30%"
        :before-close="editHandleClose">

     <span>
          <el-form ref="addForm" :model="carForm" label-width="80px" :rules="rules">
            <el-form-item label="车队名字" prop="carName">
              <el-input type="text" v-model="carForm.carName" placeholder="车队名字" ></el-input>
            </el-form-item>

            <el-form-item label="车队容量" prop="joinChannelLimit">
              <el-input type="text" @input="carJoinChannelLimitNum()" v-model="carForm.joinChannelLimit" placeholder="输入数字"></el-input>
            </el-form-item>

            <el-form-item label="已有车队" prop="joinedChannelCount">
              <el-input type="text" @input="carJoinChannelLimitNum()" v-model="carForm.joinedChannelCount" placeholder="输入数字"></el-input>
            </el-form-item>

            <el-form-item label="车队门槛" prop="carPower">
               <el-select v-model="carForm.carPower" placeholder="请选择上车门槛">
                <el-option
                    v-for="item in carPowers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="车队类型" prop="type">
               <el-select disabled v-model="carForm.type" placeholder="请选择车队类型">
                <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
      </span>

      <span slot="footer" class="dialog-footer">
    <el-button @click="carEditVisible = false">取消</el-button>
    <el-button type="primary" @click="editCar">修改</el-button>
  </span>
    </el-dialog>


    <!--  车队手动上车-->
    <el-dialog
        :visible="upCarVisible"
        width="20%"
        :before-close="upCarHandleClose">

      <center>

         <span>
             <el-select  v-model="doUpCarData.channel_id" filterable placeholder="上车频道或群组">
              <el-option
                  v-for="item in valuesCar"
                  :key="item.channel_id"
                  :label="item.label"
                  :value="item.channel_id">
              </el-option>
            </el-select>
          </span>
      </center>

      <span slot="footer" class="dialog-footer">
    <el-button @click="upCarHandleClose">取消</el-button>
    <el-button type="primary" @click="doUpCar">上车</el-button>
  </span>
    </el-dialog>

    <el-select class="carTypeSelector" v-model="carType" placeholder="选择车队类型">
      <el-option
          v-for="item in types"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          @click.native="changeCurrentPageHandler(1)">
      </el-option>
    </el-select>


    <el-divider></el-divider>

    <el-table
        :data="carDataList"
        height="700"
        style="width: 100%">

      <el-table-column
          label="车队名称"
          align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ scope.row.type =='group'? '群组-' + scope.row.carName: '频道-' +  scope.row.carName }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="车队容量"
          align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">可容纳{{ scope.row.joinChannelLimit }}个</el-tag>
          </div>
        </template>
      </el-table-column>


      <el-table-column
          label="已加入"
          align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag size="">已加入{{ scope.row.joinedChannelCount }}个</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          label="车队门槛"
          align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag size="">{{ scope.row.carPower }}人</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          label="车队类型"
          align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag size="">{{ scope.row.type == 'group'? '群组': '频道' }}</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          label="车队状态"
          align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag :type="scope.row.joinChannelLimit < scope.row.joinedChannelCount? 'danger':'success'" size="">{{ scope.row.joinChannelLimit < scope.row.joinedChannelCount? '已满': '可加入 | ' + scope.row.joinedChannelCount + '/' + scope.row.joinChannelLimit   }}</el-tag>
          </div>
        </template>
      </el-table-column>


      <el-table-column
          label="操作"
          align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">修改
          </el-button>
          <el-button
              size="mini"
              @click="handleUpCar(scope.$index, scope.row)">上车
          </el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination
        @current-change="changeCurrentPageHandler"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :page-count="pagination.totalPage"
        layout="prev, pager, next"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
import message from "element-ui/packages/message";

export default {
  name: "carList",
  data() {
    return {
      carEditVisible: false,
      carDataList: [],
      upCarVisible: false,
      valuesCar:[],
      doUpCarData:{},

      carType:"",

      types: [
        {
          value:"group",
          label:"群组"
        },
        {
          value:"channel",
          label:"频道"
        },
      ],

      carPowers: [
        {
          value:"1",
          label:"1人起"
        },
        {
          value:"100",
          label:"100人起"
        },
        {
          value:"200",
          label:"200人起"
        },
        {
          value:"500",
          label:"500人起"
        },
        {
          value:"1k",
          label:"1k人起"
        },
        {
          value:"2k",
          label:"2k人起"
        },
        {
          value:"3k",
          label:"3k人起"
        },
        {
          value:"5k",
          label:"5k人起"
        },
        {
          value:"8k",
          label:"8k人起"
        },
        {
          value:"10k",
          label:"10k人起"
        },
        {
          value:"12k",
          label:"12k人起"
        },
        {
          value:"15k",
          label:"15k人起"
        },
        {
          value:"20k",
          label:"20k人起"
        },
        {
          value:"30k",
          label:"30k人起"
        },
        {
          value:"50k",
          label:"50k人起"
        },
        {
          value:"100k",
          label:"100k人起"
        },
        {
          value:"200k",
          label:"200k人起"
        },
        {
          value:"300k",
          label:"300k以上"
        },
      ],

      carAddVisible: false,
      carForm: {},
      rules: {
        carName1: [{required: true, message: '必填', trigger: "blur"}],
        carName2: [{required: true, message: '必填', trigger: "blur"}],
        joinChannelLimit: [{required: true, message: '必填', trigger: "blur"}],
        joinedChannelCount: [{required: true, message: '必填', trigger: "blur"}],
        carPower: [{required: true, message: '必填', trigger: "blur"}],
        type: [{required: true, message: '必填', trigger: "blur"}],
        carName: [{required: true, message: '必填', trigger: "blur"}],
      },


      pagination: {
        // 每页多找个
        pageSize: 10,
        // 总页数
        totalPage: 0,
        // 获取页码
        activePage: 0,
        // 总条数
        total: 0,
        // 当前页
        currentPage: 1,
      },
    }
  },

  created() {
    window.document.title = "车队列表"
    this.changeCurrentPageHandler(this.pagination.currentPage)
  },

  methods: {

    // 获取数据
    changeCurrentPageHandler(currentPage) {
      this.httpRequest.get("back/car/requestPageList?page=" + currentPage +
          "&limit=" + this.pagination.pageSize +
          "&type=" + this.carType +
          "&orderFiled=id" +
          "&orderType=0")
          .then(response => {

            // console.log(response)
            this.pagination.currentPage = response.data.carList.currentPage
            this.pagination.pageSize = response.data.carList.pageSize
            this.pagination.total = response.data.carList.totalSize
            this.pagination.totalPage = response.data.carList.totalPage
            this.carDataList = response.data.carList.resultList
          })
    },


    // 关闭添加车队对话框
    addHandleClose() {
      this.carAddVisible = false
    },

    // 打开添加车队对话框
    showProductCategoryAddForm() {
      this.carForm = {}
      this.carAddVisible = true
    },

    carJoinChannelLimitNum() {

      if (isNaN(parseInt(this.carForm.joinChannelLimit))) {
        this.carForm.joinChannelLimit = null
        this.$message.error("数量必须数字")
      }

      if (this.carForm.joinChannelLimit === '-') {
        this.carForm.joinChannelLimit = null
        this.$message.error("数量必须大于0")
      }
    },


    doUpCar(){

      if (this.doUpCarData.channel_id){

        this.httpRequest.put("/back/car/upCar", this.doUpCarData).then(response => {
          this.changeCurrentPageHandler(this.pagination.currentPage)
        })

        this.upCarVisible = false
        this.doUpCarData = {}

      }else {
        this.$message.error("先选择频道或群组")
      }

    },


    // 添加车队
    addCar() {

      // console.log(this.carForm)

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          // console.log(this.carForm)
          this.httpRequest.post("/back/car/addCar", this.carForm).then(response => {
            this.changeCurrentPageHandler(1)
            this.carAddVisible = false
            this.carForm = {}
          })
        } else {
          message.error("请完善数据")
        }
      })
    },



    // 打开编辑车队对话框
    handleEdit(index, data) {
      // console.log(data)
      this.carForm = data
      this.carEditVisible = true

    },

    // 手动上车
    handleUpCar(index, data) {
      // 请求数据 为上车的所有车队
      this.doUpCarData.car_id = data.id

      this.httpRequest.get("/back/u_channel/valuesCar/"+data.type).then(response => {
        this.valuesCar = response.data.valueCarVoList
      })

      // 打开上车对话框
      this.upCarVisible = true

    },

    // 关闭车队编辑对话框
    editHandleClose() {
      this.carEditVisible = false
    },

    // 关闭上车对话框
    upCarHandleClose() {
      this.doUpCarData = {}
      this.upCarVisible = false

    },


    // 编辑车队
    editCar() {

      // console.log(this.carForm)

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          // console.log(this.carForm)
          this.httpRequest.put("/back/car/editCar", this.carForm).then(response => {
            this.changeCurrentPageHandler(this.pagination.currentPage)
            this.carEditVisible = false
            this.carForm = {}
          })
        } else {
          message.error("请完善数据")
        }
      })
    },

  }


}
</script>

<style scoped>
.addCar {
  margin-left: 20px;
}

.queryProductCategory {
  width: 15%;
  position: absolute;
  right: 50px;
}

.queryButton {
  position: absolute;
  right: 400px;
}

.datatable {
  position: relative;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: rgb(96, 98, 102);
  overflow: hidden;
  flex: 1 1 0%;
}

.datatable td, .datatable th {
  padding: 12px 0;
  min-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.carTypeSelector {
  float: right;
}


</style>