<template>
  <div>
    <h2 align="center" style="font-family: 楷体">系统概述</h2>
    <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
    <div id="statisticalOne" style="width: 600px;height:400px; float: left; left: 10%; margin-top: 10%"></div>
    <div id="statisticalTwo" style="width: 600px;height:400px; float: left; left: 10%; margin-top: 10%"></div>
  </div>
</template>

<script>
export default {
  name: "SystemIndex",

  data() {
    return {
      statisticsChannelData:[],
      statisticsFleetData: []
    }
  },

  created() {
    window.document.title = "系统视图"


  },

  beforeMount() {

  },

  beforeCreate() {


  },
 async mounted() {

    await this.requeststatisticsChannelData()
    await this.requestFleetStatisticsData()
    this.statisticalOne();
    this.statisticalTwo()

  },

  methods:{

    async requeststatisticsChannelData(){
      await this.httpRequest.get("/back/statistics/channel").then(response => {
        console.log(response)
         this.statisticsChannelData = response.data.statisticsChannelData
      })
    },

    async requestFleetStatisticsData(){
      await this.httpRequest.get("/back/statistics/fleet").then(response => {
        console.log(response)
        this.statisticsFleetData = response.data.statisticsFleetData
      })
    },

    statisticalOne(){
      var statisticalOne = this.$echarts.init(document.getElementById('statisticalOne'));


      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '实体概览',
          subtext: '统计',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '实体',
            type: 'pie',
            radius: '60%',
            data: this.statisticsChannelData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      statisticalOne.setOption(option);
    },

    statisticalTwo(){
      var statisticalTwo = this.$echarts.init(document.getElementById('statisticalTwo'));


      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '车队概览',
          subtext: '统计',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '车队',
            type: 'pie',
            radius: '60%',
            data: this.statisticsFleetData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      statisticalTwo.setOption(option);
    },

  }



}
</script>

<style scoped>

</style>